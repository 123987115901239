import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchNFTs } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

import Navigation from "./Navigation";
import ContractAddress from "./ContractAddress";
import MintButton from "./MintButton";
import AccentText from "./AccentText";
import MintedToken from "./MintedToken";

import ResponsiveWrapper from "./ResponsiveWrapper";
import StyledLogo from "./StyledLogo";
import StyledFuseLogoLarge from "./StyledFuseLogoLarge";
import StyledImage from "./StyledImage";
import StyledGIF from "./StyledGIF";
import UnstyledGIF from "./UnstyledGIF";
import StyledLink from "./StyledLink";

function About() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchNFTs(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const token = data.tokens.map(token => <MintedToken key={token.id} marketplaceLink={CONFIG.MARKETPLACE_LINK} marketplaceName={CONFIG.MARKETPLACE} token={token} />)

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: "24px 0", backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <Navigation />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImage alt={"cozy-cosmonauts-01"} src={"/config/images/cozy-cosmonauts-01.gif"} /> */}
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"flex-start"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              overflowY: "scroll",
              overflowX: "hidden",
              height: "720px", 
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
              <>

                  <s.Container ai={"center"} jc={"center"}>

                    <s.TextTitle style={{ textAlign: "center" }}>
                      The first profile picture NFT project on
                    </s.TextTitle>

                    <s.SpacerMedium />

                    <StyledFuseLogoLarge alt={"FUSE Logo"} src={"/config/images/fuse.svg"} />

                    <s.SpacerXLarge />

                    <StyledImage alt={"cozy-cosmonauts-01"} src={"/config/images/cozy-cosmonauts-01.gif"} />

                    <s.SpacerXLarge />

                    <s.TextDescription>
                      Cozy Cosmonauts is a <span style={{ fontWeight: "bold" }}>FUSE ORIGINAL</span>. This exclusive collection of 999 NFT, only exists on the <StyledLink target={"_blank"} href={"https://fuse.io"}>FUSE Blockchain</StyledLink>.
                    </s.TextDescription>

                    <s.SpacerXLarge />

                    <StyledGIF alt={"cozy-cosmonauts-04"} src={"/config/images/04.gif"} />

                    <s.SpacerXLarge />

                    <s.TextDescription>
                      What <StyledLink target={"_blank"} href={"https://opensea.io/collection/boredapeyachtclub"}>Bored Apes</StyledLink> are to the Ethereum Blockchain, these Cozy Cosmonauts are to the FUSE Blockchain. It's currently one of the very few blue chips on the FUSE Network.
                    </s.TextDescription>

                    <s.SpacerXLarge />

                    <StyledGIF alt={"cozy-cosmonauts-05"} src={"/config/images/05.gif"} />

                    <s.SpacerXLarge />

                    <s.TextDescription>
                      Use your personal Cozy Cosmonaut on Telegram, WhatsApp, Discord and Twitter as your profile picture and show the world that you are a true <span style={{ fontWeight: "bold" }}>FUSE PIONEER</span>.
                    </s.TextDescription>

                    <s.SpacerXLarge />

                    <StyledGIF alt={"cozy-cosmonauts-03"} src={"/config/images/03.gif"} />

                    <s.SpacerXLarge />

                    <s.TextDescription>
                      All 999 are minted and the project is already sold out but you can still sell and buy Cozy Cosmonauts on <StyledLink target={"_blank"} href={"https://tofunft.com/collection/cozycosmonauts/rarities"}>TOFU NFT Marketplace</StyledLink> and join our Cozy <StyledLink target={"_blank"} href={"https://t.me/CozyCosmonauts"}>Telegram</StyledLink> Group.
                    </s.TextDescription>

                  </s.Container>
              </>
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImage alt={"cozy-cosmonauts-02"} src={"/config/images/cozy-cosmonauts-02.gif"} style={{ transform: "scaleX(-1)" }} /> */}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescriptionSmall
            style={{
              textAlign: "center",
            }}
          >
            Cozy Cosmonauts is the first profile picture NFT project on the FUSE Network
            <ContractAddress contractLink={CONFIG.SCAN_LINK} contractAddress={CONFIG.CONTRACT_ADDRESS} />
          </s.TextDescriptionSmall>
          <s.SpacerSmall />
          <s.TextDescriptionSmall
            style={{
              textAlign: "center",
            }}
          >        
          </s.TextDescriptionSmall>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default About;
