import styled from "styled-components";

const Component = styled.button`
  padding: 10px;
  font-size: 12px;
  border-radius: 50px;
  border: none;
  background-color: var(--accent-secondary);
  font-weight: bold;
  color: var(--accent-text-secondary);
  width: 100%;
  cursor: pointer;
  margin-right: 8px;
  white-space: nowrap;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

const Tag = (props) => {
  return <Component {... props} />;
};

export default Tag;