import styled from "styled-components";
import * as s from "./styles/globalStyles";
import StyledLink from "./StyledLink";
import StyledButton from "./StyledButton";
import Tag from "./Tag";
import {
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton
} from "react-share";

const Component = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  margin: 20px 0;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

const MintedToken = (props) => {
  return <>
    <s.Line />
    <Component src={props.token.imageUrl} />
    <s.TextDescription
      style={{
        fontSize: "20px",
        margin: "16px 0",
        textAlign: "center",
        color: "var(--accent-text)",
        lineHeight: 1
      }}
    >
      {props.token.name}
    </s.TextDescription>

    <s.TextDescription
      style={{
        textAlign: "center",
        color: "var(--accent-text)",
        lineHeight: 1
      }}
    >
      <s.Container
        flex={2}
        jc={"center"}
        ai={"center"}
        style={{
          flexDirection: "row"
        }}
      >
        <Tag>Background: {props.token.background}</Tag>
        <Tag>Skin: {props.token.skin}</Tag>
        <Tag>Head: {props.token.head}</Tag>

      </s.Container>

      <s.Container
        flex={2}
        jc={"center"}
        ai={"center"}
        style={{
          flexDirection: "row",
          marginTop: "8px"
        }}
      >
        <Tag>Face: {props.token.face}</Tag>
        <Tag>Body: {props.token.body}</Tag>
        <Tag>Feet: {props.token.feet}</Tag>
      </s.Container>
    </s.TextDescription>

    <s.Container
        flex={2}
        jc={"center"}
        ai={"center"}
        style={{
          flexDirection: "row",
          marginTop: "8px"
        }}
      >
      <s.TextDescription
        style={{
          fontSize: "16px",
          textAlign: "center",
          color: "var(--accent-text)",
          lineHeight: 2,
          fontWeight: "bold"
        }}
      >
        share your COZY with others
      </s.TextDescription>
    </s.Container>
    
    <s.Container
        flex={2}
        jc={"center"}
        ai={"center"}
        style={{
          flexDirection: "row",
          marginTop: "8px"
        }}
      >
      <TwitterShareButton url={`https://cozycosmonauts.com/share/${props.token.id}`}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <s.SpacerMedium />
      <TelegramShareButton url={`https://cozycosmonauts.com/share/${props.token.id}`}>
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton >
    </s.Container>

    <StyledLink target={"_blank"} href={props.marketplaceLink}>
    <s.SpacerMedium />
      <StyledButton>
        SELL
      </StyledButton>
    </StyledLink>
  </>
};

export default MintedToken;