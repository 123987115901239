import StyledButton from "./StyledButton";

const MintButton = (props) => {
  return (
    <StyledButton {... props}>
      MINT
    </StyledButton>
  );
};

export default MintButton;