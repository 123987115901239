import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Gallery from "./Gallery";
import About from "./About";
import Share from "./Share";
import Admin from "./Admin";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/reset.css";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <App /> */}
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/mint" element={<App />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/share/:token_id" element={<Share />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
