import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

import Navigation from "./Navigation";
import TokenSupply from "./TokenSupply";
import ContractAddress from "./ContractAddress";
import SaleEnded from "./SaleEnded";
import TokenCosts from "./TokenCosts";
import ConnectButton from "./ConnectButton";
import AccentText from "./AccentText";

import StyledButton from "./StyledButton";
import StyledRoundButton from "./StyledRoundButton";
import ResponsiveWrapper from "./ResponsiveWrapper";
import StyledLogo from "./StyledLogo";
import StyledFuseLogo from "./StyledFuseLogo";
import StyledImage from "./StyledImage";
import StyledLink from "./StyledLink";

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState();
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let gasPrice = CONFIG.GAS_PRICE;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        gasPrice: gasPrice,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        try {
          let error = JSON.parse(err.toString().replace("Error: Transaction has been reverted by the EVM:",""));
          const txLink = CONFIG.TX_LINK + error.transactionHash;
          setFeedback(<>Sorry, something went wrong, click <StyledLink target={"_blank"} href={txLink}>here</StyledLink> to inspect your transaction</>);
        } catch {
          setFeedback(<>Sorry, something went wrong: {err.message}</>);
        }
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(<>WOW, this COZY is yours! Checkout your <StyledLink target={"_blank"} href={"/gallery"}>gallery</StyledLink> to view it.</>);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    console.log(config);
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: "24px 0", backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <Navigation />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImage alt={"cozy-cosmonauts-01"} src={"/config/images/cozy-cosmonauts-01.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            {CONFIG.PUBLIC_SALE == false &&
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                  }}
                >
                  PRE SALE STARTED - whitelist only
                </s.TextDescription>
                <s.TextDescriptionSmall
                  style={{
                    textAlign: "center",
                  }}
                >
                  (whitelisted is everybody who minted a <StyledLink target={"_blank"} href={"https://www.fusel.io/"}>FUSEL NFT</StyledLink> before November 27th - 8pm UTC)
                </s.TextDescriptionSmall>
                <s.SpacerXSmall />
                <s.TextDescriptionSmall
                  style={{
                    textAlign: "center",
                  }}
                >
                  PUBLIC SALE STARTS NOVEMBER 29TH - 10am UTC
                </s.TextDescriptionSmall>
              </>
            }
            <s.SpacerMedium />
            <s.TextTitle
              style={{
                textAlign: "center",
              }}
            >
              A collection of 999 unique NFT
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
              }}
            >
              minted on <StyledFuseLogo alt={"FUSE Logo"} src={"/config/images/fuse.svg"} /> and stored on IPFS
            </s.TextTitle>
            <s.SpacerLarge />
            <s.SpacerLarge />
            <s.TextTitle
              style={{
                textAlign: "center",
              }}
            >        
            </s.TextTitle>

            <TokenSupply totalSupply={data.totalSupply} maxSupply={CONFIG.MAX_SUPPLY} />

            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <SaleEnded projectName={CONFIG.NFT_NAME} marketplaceLink={CONFIG.MARKETPLACE_LINK} marketplaceName={CONFIG.MARKETPLACE} />
            ) : (
              <>
                <s.SpacerLarge />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"}>
                      <ConnectButton onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }} />
                      <s.SpacerSmall />
                      <TokenCosts symbol={CONFIG.SYMBOL} costs={CONFIG.DISPLAY_COST} networkName={CONFIG.NETWORK.SYMBOL}/>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <AccentText text={blockchain.errorMsg} />
                        </>
                      ) : null}
                    </s.Container>
                  </>
                ) : (
                  <>
                    <AccentText text={feedback} />
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <AccentText text={mintAmount} />
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={(claimingNft ? 1 : 0) || CONFIG.PAUSED}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <TokenCosts symbol={CONFIG.SYMBOL} costs={CONFIG.DISPLAY_COST} networkName={CONFIG.NETWORK.SYMBOL}/>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImage
              alt={"cozy-cosmonauts-02"}
              src={"/config/images/cozy-cosmonauts-02.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescriptionSmall
            style={{
              textAlign: "center",
            }}
          >
            Cozy Cosmonauts is the first profile picture NFT project on the FUSE Network
            <ContractAddress contractLink={CONFIG.SCAN_LINK} contractAddress={CONFIG.CONTRACT_ADDRESS} />
          </s.TextDescriptionSmall>
          <s.SpacerSmall />
          <s.TextDescriptionSmall
            style={{
              textAlign: "center",
            }}
          >        
          </s.TextDescriptionSmall>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
