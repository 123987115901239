import StyledButton from "./StyledButton";

const WithdrawButton = (props) => {
  return (
    <StyledButton {... props}>
      WITHDRAW
    </StyledButton>
  );
};

export default WithdrawButton;