import { Link  } from "react-router-dom";
import StyledLink from "./StyledLink";

const Navigation = (props) => {
  return (
    <nav style={{
      textAlign: "center",
      color: "var(--primary-text)",
      padding: "20px 0"
    }}>

      <Link to="/" style={{
        textAlign: "center",
        textDecoration: "none",
        color: "var(--primary-text)",
        margin: "0 8px",
        fontSize: "1.4em",
        fontWeight: "bold"
        }}
      >
        About
      </Link>
      <StyledLink href="https://tofunft.com/collection/cozycosmonauts/rarities" target="_blank" style={{
        textAlign: "center",
        textDecoration: "none",
        color: "var(--primary-text)",
        margin: "0 8px",
        fontSize: "1.4em",
        fontWeight: "bold"
        }}
      >
        Trade
      </StyledLink>
      {/* <Link to="/gallery" style={{
        textAlign: "center",
        textDecoration: "none",
        color: "var(--primary-text)",
        margin: "0 8px",
        fontSize: "1.4em",
        fontWeight: "bold"
        }}
      >
        Gallery
      </Link> */}

      <StyledLink href="https://twitter.com/CozyCosmonauts" target="_blank" style={{
        textAlign: "center",
        textDecoration: "none",
        color: "var(--primary-text)",
        margin: "0 8px",
        fontSize: "1.4em",
        fontWeight: "bold"
        }}
      >
        Twitter
      </StyledLink>
      {/* <StyledLink href="https://t.me/CozyCosmonauts" target="_blank" style={{
        textAlign: "center",
        textDecoration: "none",
        color: "var(--primary-text)",
        margin: "0 8px",
        fontSize: "1.4em",
        fontWeight: "bold"
        }}
      >
        Telegram
      </StyledLink> */}
    </nav>
  );
};

export default Navigation;