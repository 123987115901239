import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNFTs } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

import Navigation from "./Navigation";
import ContractAddress from "./ContractAddress";
import MintButton from "./MintButton";
import AccentText from "./AccentText";
import MintedToken from "./MintedToken";

import ResponsiveWrapper from "./ResponsiveWrapper";
import StyledLogo from "./StyledLogo";
import StyledFuseLogoLarge from "./StyledFuseLogoLarge";
import StyledImage from "./StyledImage";
import StyledGIF from "./StyledGIF";
import UnstyledGIF from "./UnstyledGIF";
import StyledLink from "./StyledLink";

import { useParams } from "react-router-dom";

function Share() {
  const { token_id } = useParams();
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: "24px 0", backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <Navigation />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImage alt={"cozy-cosmonauts-01"} src={"/config/images/cozy-cosmonauts-01.gif"} /> */}
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"flex-start"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              overflowY: "scroll",
              overflowX: "hidden",
              height: "720px", 
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
              <>
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextTitle style={{ textAlign: "center" }}>
                      Cozy Cosmonaut #{token_id}
                    </s.TextTitle>
                    <s.SpacerMedium />
                    <StyledImage alt={"MY COZY"} src={`https://gateway.pinata.cloud/ipfs/QmWszZ58xf9avo6pV7WWpwa6vzT7tpGfTX3SNGEayxqBc5/${token_id}.png`} />

                    <s.SpacerXLarge />

                    <s.TextDescription style={{ textAlign: "center" }}>
                      What <StyledLink target={"_blank"} href={"https://opensea.io/collection/boredapeyachtclub"}>Bored Apes</StyledLink> are to the Ethereum Blockchain, these Cozy Cosmonauts are to the <StyledLink target={"_blank"} href={"https://fuse.io"}>FUSE Blockchain</StyledLink>.
                    </s.TextDescription>

                    <s.SpacerXLarge />

                    <MintButton onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "/mint";
                    }} />
                  </s.Container>
              </>
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImage alt={"cozy-cosmonauts-02"} src={"/config/images/cozy-cosmonauts-02.gif"} style={{ transform: "scaleX(-1)" }} /> */}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescriptionSmall
            style={{
              textAlign: "center",
            }}
          >
            Cozy Cosmonauts is the first profile picture NFT project on the FUSE Network
            <ContractAddress contractLink={CONFIG.SCAN_LINK} contractAddress={CONFIG.CONTRACT_ADDRESS} />
          </s.TextDescriptionSmall>
          <s.SpacerSmall />
          <s.TextDescriptionSmall
            style={{
              textAlign: "center",
            }}
          >        
          </s.TextDescriptionSmall>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default Share;
