import * as s from "./styles/globalStyles";

const AccentText = (props) => {
  return (
    <s.TextDescription
      style={{
        textAlign: "center",
        fontSize: "20px",
        fontFamily: "coder",
        color: "var(--accent-text)",
      }}
    >
      {props.text}
    </s.TextDescription>
  );
};

export default AccentText;