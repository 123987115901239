import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchNFTs } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

import Navigation from "./Navigation";
import ContractAddress from "./ContractAddress";
import ConnectButton from "./ConnectButton";
import AccentText from "./AccentText";
import MintedToken from "./MintedToken";

import ResponsiveWrapper from "./ResponsiveWrapper";
import StyledLogo from "./StyledLogo";
import StyledGIF from "./StyledGIF";
import StyledLink from "./StyledLink";

function Gallery() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchNFTs(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const token = data.tokens.map(token => <MintedToken key={token.id} marketplaceLink={CONFIG.MARKETPLACE_LINK} marketplaceName={CONFIG.MARKETPLACE} token={token} />)

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: "24px 0", backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <Navigation />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>

          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"flex-start"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              overflowY: "scroll",
              overflowX: "hidden",
              height: "720px", 
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                      }}
                    >
                      View your minted NFTs
                    </s.TextTitle>

                    <s.SpacerLarge />

                    <s.TextDescription
                      style={{
                        textAlign: "center",
                      }}
                    >
                      If you don't own any Cozy Cosmonauts, you can still use our super cool <StyledLink target={"_blank"} href={"https://t.me/addstickers/cozycosmonauts"}>Telegram Sticker</StyledLink> or <StyledLink target={"_blank"} href={"https://tenor.com/users/cozycosmonauts"}>GIFs</StyledLink> to spread the word!  And while you're here, we invite you to join our cozy FUSE community on <StyledLink target={"_blank"} href={"https://t.me/CozyCosmonauts"}>Telegram</StyledLink>.
                    </s.TextDescription>

                    <s.SpacerSmall />

                    <StyledGIF alt={"cozy-cosmonauts-01"} src={"/config/images/01-no-logo.gif"} />

                    <s.SpacerSmall />

                    <ConnectButton onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }} />

                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <AccentText text={blockchain.errorMsg} />
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                      }}
                    >
                      You own {data.balance} Cozy Cosmonauts
                    </s.TextTitle>

                    <s.SpacerLarge />

                    {data.balance === 0 &&
                      <>
                        <s.SpacerLarge />

                        <s.TextDescription
                          style={{
                            textAlign: "center",
                          }}
                        >
                          but you can still use our super cool <StyledLink target={"_blank"} href={"https://t.me/addstickers/cozycosmonauts"}>Telegram Sticker</StyledLink> or <StyledLink target={"_blank"} href={"https://tenor.com/users/cozycosmonauts"}>GIFs</StyledLink> to spread the word! And while you're here, we invite you to join our cozy FUSE community on <StyledLink target={"_blank"} href={"https://t.me/CozyCosmonauts"}>Telegram</StyledLink>.
                        </s.TextDescription>

                        <s.SpacerSmall />

                        <StyledGIF alt={"cozy-cosmonauts-01"} src={"/config/images/01-no-logo.gif"} />

                        <s.SpacerSmall />
                      </>
                    }
                    
                    {data.balance > 0 && CONFIG.REVEALED == false &&
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Your NFTs will be revealed on November 30th, 10am UTC
                        </s.TextDescription>
                      </>
                    }

                    {data.balance > 0 && CONFIG.REVEALED == true &&
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Be a true <StyledLink target={"_blank"} href={"https://t.me/CozyCosmonauts"}>FUSE Pioneer</StyledLink> and use your COZY as 
                        </s.TextDescription>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                          }}
                        >
                          your <span style={{ fontWeight: "bold" }}>profile picture</span> on <span style={{ fontWeight: "bold" }}>Telegram and Twitter</span> now
                        </s.TextDescription>
                      </>
                    }

                    <s.SpacerSmall />
                    {token}
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    </s.Container>
                  </>
                )}
              </>
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>

          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescriptionSmall
            style={{
              textAlign: "center",
            }}
          >
            Cozy Cosmonauts is the first profile picture NFT project on the FUSE Network
            <ContractAddress contractLink={CONFIG.SCAN_LINK} contractAddress={CONFIG.CONTRACT_ADDRESS} />
          </s.TextDescriptionSmall>
          <s.SpacerSmall />
          <s.TextDescriptionSmall
            style={{
              textAlign: "center",
            }}
          >        
          </s.TextDescriptionSmall>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default Gallery;
