import styled from "styled-components";

const Component = styled.a`
  color: var(--accent-text);
  text-decoration: underline;
`;

const StyledLink = (props) => {
  return <Component {... props} />;
};

export default StyledLink;