// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";

import Onboard from '@fuseio/bnc-onboard';

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();

    const wallets = [
      { walletName: "metamask", preferred: true },
      { walletName: "walletConnect", preferred: true, rpc: { ['122']: "https://rpc.fuse.io"} },
    ]

    const onboard = Onboard({
      dappId: CONFIG.DAPP_ID,
      networkId: CONFIG.NETWORK.ID,
      walletSelect: {
        wallets: wallets
      },
    });

    await onboard.walletSelect();
    await onboard.walletCheck();

    const currentState = onboard.getState();
    let provider = currentState.wallet.provider;
    let account = currentState.address;
    let networkId = currentState.appNetworkId;

    try {
      if (networkId == CONFIG.NETWORK.ID) {
        Web3EthContract.setProvider(provider);
        let web3 = new Web3(provider);
    
        const SmartContract = new Web3EthContract(
          abi,
          CONFIG.CONTRACT_ADDRESS
        );
        dispatch(
          connectSuccess({
            account: account,
            smartContract: SmartContract,
            web3: web3,
          })
        );
      } else {
        dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
      }
    } catch (err) {
      console.log(err);
      dispatch(connectFailed("Something went wrong."));
    }

  };
};