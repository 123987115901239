import styled from "styled-components";

const Component = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

const ResponsiveWrapper = (props) => {
  return <Component {... props} />;
};

export default ResponsiveWrapper;