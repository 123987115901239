import * as s from "./styles/globalStyles";

const TokenSupply = (props) => {
  return (
    <>
    <s.TextTitle
        style={{
          textAlign: "center",
          fontWeight: "bold",
          color: "var(--accent-text-secondary)",
        }}
      >
      ALREADY MINTED
    </s.TextTitle>
    <s.TextTitle
        style={{
          textAlign: "center",
          fontWeight: "bold",
          color: "var(--accent-text-secondary)",
        }}
      >
      {props.totalSupply} / {props.maxSupply}
    </s.TextTitle>
    </>
  );
};

export default TokenSupply;