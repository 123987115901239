import styled from "styled-components";

const Component = styled.button`
  font-size: 24px;
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
  padding: 20px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-family: "coder";
  color: var(--secondary-text);
  width: 180px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :disabled {
    opacity: 40%;
  }
`;

const StyledButton = (props) => {
  return <Component {... props} />;
};

export default StyledButton;