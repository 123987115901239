import styled from "styled-components";

const Component = styled.img`
  border: 4px solid #fff;
  width: 300px;
  @media (min-width: 1200px) {
    width: 720px;
  }
  transition: width 0.5s;
`;

const UnstyledGIF = (props) => {
  return <Component {... props} />;
};

export default UnstyledGIF;