import * as s from "./styles/globalStyles";

const TokenCosts = (props) => {
  return (
    <s.TextDescriptionSmall
      style={{ textAlign: "center", color: "var(--primary-text)" }}
    >
      1 {props.symbol} costs {props.costs}{" "}{props.networkName}
    </s.TextDescriptionSmall>
  );
};

export default TokenCosts;