import * as s from "./styles/globalStyles";
import StyledLink from "./StyledLink";

const ContractAddress = (props) => {
  return (
    <s.TextDescriptionSmall>
      <StyledLink target={"_blank"} href={props.contractLink}>
        {props.contractAddress}
      </StyledLink>
    </s.TextDescriptionSmall>
  );
};

export default ContractAddress;