import styled from "styled-components";

const Component = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 300px;
  @media (min-width: 1200px) {
    width: 350px;
  }
  transition: width 0.5s;
`;

const StyledImage = (props) => {
  return <Component {... props} />;
};

export default StyledImage;