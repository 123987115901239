import * as s from "./styles/globalStyles";
import StyledLink from "./StyledLink";

const SaleEnded = (props) => {
  return (
    <>
      <s.TextTitle
        style={{ textAlign: "center", color: "var(--accent-text)" }}
      >
        The sale has ended.
      </s.TextTitle>
      <s.TextDescription
        style={{ textAlign: "center", color: "var(--accent-text)" }}
      >
        You can still find {props.projectName} on
      </s.TextDescription>
      <s.SpacerSmall />
      <StyledLink target={"_blank"} href={props.marketplaceLink}>
        {props.marketplaceName}
      </StyledLink>
    </>
  );
};

export default SaleEnded;