import styled from "styled-components";

const Component = styled.img`
  border-radius: 10%;
  width: 300px;
  @media (min-width: 1200px) {
    width: 350px;
  }
  transition: width 0.5s;
`;

const StyledGIF = (props) => {
  return <Component {... props} />;
};

export default StyledGIF;