import styled from "styled-components";

const Component = styled.img`
  width: 100px;
  @media (min-width: 767px) {
    width: 100;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const StyledFuseLogo = (props) => {
  return <Component {... props} />;
};

export default StyledFuseLogo;