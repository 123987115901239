import styled from "styled-components";

const Component = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 200;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const StyledFuseLogoLarge = (props) => {
  return <Component {... props} />;
};

export default StyledFuseLogoLarge;