// log
import store from "../store";
import _ from "underscore";
import rarity from "./rarity";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchBalanceSuccess = (payload) => {
  return {
    type: "CHECK_BALANCE_SUCCESS",
    payload: payload,
  };
};

const fetchNFTSuccess = (payload) => {
  return {
    type: "CHECK_NFT_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

const fetchBalance = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalBalanceInWei = await store
        .getState()
        .blockchain.smartContract.methods.totalBalance()
        .call();

      let totalBalance = (await store.getState().blockchain.web3.utils.fromWei(totalBalanceInWei)).toString();

      dispatch(
        fetchBalanceSuccess({
          totalBalance,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

const fetchNFTs = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      console.log(account);
      let methods = await store.getState().blockchain.smartContract.methods;
      let balance = await methods.balanceOf(account).call();
      let tokens = [];

      _.times(balance, async function(index){
        let tokenId = await methods.tokenOfOwnerByIndex(account, index).call();
        let ipfsTokenURI = await methods.tokenURI(tokenId).call();
        let httpTokenURI = ipfsTokenURI.replace("ipfs://", "https://ipfs.io/ipfs/");

        fetch(httpTokenURI)
        .then(response => response.json())
        .then(metadata => {
          let name = metadata.name;
          let ipfsImageUrl = metadata.image;
          let httpImageUrl = ipfsImageUrl.replace("ipfs://", "https://ipfs.io/ipfs/");

          // rarity structure
          var background = traitWithRarity(metadata, "Background")
          var skin = traitWithRarity(metadata, "Skin")
          var feet = traitWithRarity(metadata, "Feet")
          var face = traitWithRarity(metadata, "Face")
          var body = traitWithRarity(metadata, "Body")
          var head = traitWithRarity(metadata, "Head")

          tokens.push(
            {
              id: tokenId,
              name: name,
              imageUrl: httpImageUrl,
              background: background,
              skin: skin,
              face: face,
              feet: feet,
              head: head,
              body: body
            }
          )

          dispatch(
            fetchNFTSuccess({
              balance,
              tokens
            })
          );
        });
      });
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

const traitWithRarity = (metadata, layerName) => {
  var traitValue = _.find(metadata.attributes, function (data) { return data.trait_type == layerName }).value;
  var layer = _.find(rarity(), function (item) { return item.layer == layerName });
  var item = _.find(layer.items, function (item) { return item.trait == traitValue });
  console.log(item);
  if(item != undefined) {
    return `${item.trait} (${item.occurrence})`
  } else {
    return 'Unknown'
  }
}

const withdrawBalance = (account) => {
  return async (dispatch) => {
    console.log(account);
    dispatch(fetchDataRequest());
    try {
      let methods = await store.getState().blockchain.smartContract.methods;

      await methods.withdraw().send(
        {
          from: account,
          gasPrice: 1000000000 // 1 gwei
        }
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export {fetchData, fetchNFTs, withdrawBalance, fetchBalance}